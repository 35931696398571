



































import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class UserInfo extends Vue {
  public member = {};
  public user_id = "";
  public info = {}
  // 获取用户信息
  //   getMember() {
  //     let _this = this;
  //     this.$api.request({
  //       url: "user/member/info",
  //       success(res) {
  //         _this.member = res.data;
  //       }
  //     });
  //   }
  getInfo() {
    let _this = this;
    this.$api.request({
      url: "balance/user/user-money",
      data:{user_id:this.user_id},
      success(res) {
          _this.info = res.data
          console.log(_this.info)
      }
    });
  }
  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.getInfo()
  }
}
